// Custom select bar

.custom-select {
    position: relative;
    width: 100%;
    height: 40px;
    color: $dark;
    background-color: #f6f6f6 !important;
    border-radius: 0;
    border: 0;
    background: none;
    padding: 0;
    font-size: 14px;
    transition: border-color .15s ease-in-out;
    margin-bottom: 23px;
}

.custom-select select {
    display: none; /*hide original SELECT element:*/
}

.select-selected {
    background-color: #f6f6f6;
}

/*style the arrow inside the select element:*/
.select-selected:after {
    position: absolute;
    content: "";
    top: 20px;
    right: 15px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: $dark transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
    top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div, .select-selected {
    color: $dark;
    padding: 8px 16px;
    border: 1px solid transparent;
    cursor: pointer;
    user-select: none;
}

/*style items (options):*/
.select-items {
    position: absolute;
    background-color: #f6f6f6;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
    display: none;
}

.select-items div:hover, .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1199px) {
    .custom-select {
        margin-bottom: 15px;
    }
}

// Custom Checkbox

.span_pseudo, .chiller_cb span:before, .chiller_cb span:after {
    content: "";
    display: inline-block;
    background: $accordian;
    width: 0;
    height: 0.2rem;
    position: absolute;
    transform-origin: 0% 0%;
}

.chiller_cb {
    position: absolute;
    height: 2rem;
    display: flex;
    align-items: center;
    right: 160px;
    z-index: 100;
    top: 33px;
}

.chiller_cb input {
    display: none;
}

.chiller_cb input:checked ~ span {
    background: $white;
    border-color: $white;
}

.chiller_cb input:checked ~ span:before {
    width: 1rem;
    height: 0.15rem;
    transition: width 0.1s;
    transition-delay: 0.3s;
}

.chiller_cb input:checked ~ span:after {
    width: 0.4rem;
    height: 0.15rem;
    transition: width 0.1s;
    transition-delay: 0.2s;
}

.chiller_cb input:disabled ~ span {
    background: $accordian;
    border-color: $white;
}

.chiller_cb input:disabled ~ label {
    color: $accordian;
}

.chiller_cb input:disabled ~ label:hover {
    cursor: default;
}

.chiller_cb label {
    padding-left: 13px;
    position: relative;
    z-index: 2;
    cursor: pointer;
    margin-bottom: 0;
}

.chiller_cb span {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    border: 2px solid #ccc;
    position: absolute;
    left: 0;
    transition: all 0.2s;
    z-index: 1;
    box-sizing: content-box;
}

.chiller_cb span:before {
    transform: rotate(-55deg);
    top: 1rem;
    left: 0.34rem;
}

.chiller_cb span:after {
    transform: rotate(35deg);
    bottom: 0.34rem;
    left: 0.2rem;
}

.chiller_cb .checks {
    width: 18px;
    height: 18px !important;
    padding: 0 !important;
    margin-left: 114px !important;
    margin-right: 0 !important;
}

// Custom Radio Button

.content-row {
    ul {
        list-style: none;
    }

    li {
        display: inline-block;
        margin-right: 19px;
        align-content: center;
    }

    input {
        visibility: hidden;
    }

    label {
        cursor: pointer;
    }

    input:checked + label {
        border: 1px solid #DCE0E2;
        background-color: #CA868C;
        box-shadow: 0 2px 3px 0 rgba(219, 219, 219, 0.5);
        color: $white;
    }
}

// CSS Arrows

// *{
//     color: #888 !important;
//     &:hover{
//       color: #aaa !important;
//     }
//   }
[class*="icono"] {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #666;
    box-sizing: border-box;

    &:after, &:before {
        content: "";
        box-sizing: border-box;
    }
}

[class*="icono-arrow1"] {
    width: 20px;
    height: 20px;
    border-width: 4px 4px 0 0;
    border-style: solid;
    margin: 10px;

    &:before {
        right: 0;
        top: -3px;
        position: absolute;
        height: 4px;
        box-shadow: inset 0 0 0 32px;
        transform: rotate(-45deg);
        width: 23px;
        transform-origin: right top;
    }

    &[class*="-left"] {
        transform: rotate(45deg);

        &[class*="-up"] {
            transform: none;
        }

        &[class*="-down"] {
            transform: rotate(90deg);
        }
    }

    &[class*="-right"] {
        transform: rotate(-135deg);

        &[class*="-up"] {
            transform: rotate(-90deg);
        }

        &[class*="-down"] {
            transform: rotate(180deg);
        }
    }

    &[class*="-up"] {
        transform: rotate(-45deg);
    }

    &[class*="-down"] {
        transform: rotate(135deg);
    }
}

[class*="icono-arrow2"] {
    width: 0;
    height: 0;
    border-width: 6px;
    border-style: solid;
    border-bottom-color: transparent;
    border-left-color: transparent;
    margin: 10px;

    &:before {
        right: 0;
        top: -2px;
        position: absolute;
        height: 2px;
        box-shadow: inset 0 0 0 32px;
        transform: rotate(-45deg);
        width: 15px;
        transform-origin: right top;
    }

    &[class*="-left"] {
        transform: rotate(45deg);

        &[class*="-up"] {
            transform: none;
        }

        &[class*="-down"] {
            transform: rotate(90deg);
        }
    }

    &[class*="-right"] {
        transform: rotate(-135deg);

        &[class*="-up"] {
            transform: rotate(-90deg);
        }

        &[class*="-down"] {
            transform: rotate(180deg);
        }
    }

    &[class*="-up"] {
        transform: rotate(-45deg);
    }

    &[class*="-down"] {
        transform: rotate(135deg);
    }
}

[class*="icono"] {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: $dark;
    box-sizing: border-box;

    &:after, &:before {
        content: "";
        box-sizing: border-box;
    }
}

[class*="icono-arrow3"] {
    width: 18px;
    height: 20px;
    border-width: 4px 4px 0 0;
    border-style: solid;
    margin: 10px;
    border-radius: 0 2px 0 0;

    &:before, &:after {
        border-radius: 2px;
        position: absolute;
    }

    &:before {
        right: 0;
        top: -3px;
        width: 23px;
        height: 4px;
        transform: rotate(-45deg);
        transform-origin: right top;
        box-shadow: inset 0 0 0 32px;
    }

    &:after {
        width: 4px;
        height: 4px;
        left: -2px;
        top: -4px;
        box-shadow: inset 0 0 0 32px, 16px 17px;
    }

    &[class*="-left"] {
        transform: rotate(45deg);

        &[class*="-up"] {
            transform: none;
        }

        &[class*="-down"] {
            transform: rotate(90deg);
        }
    }

    &[class*="-right"] {
        transform: rotate(-135deg);

        &[class*="-up"] {
            transform: rotate(-90deg);
        }

        &[class*="-down"] {
            transform: rotate(180deg);
        }
    }

    &[class*="-up"] {
        transform: rotate(-45deg);
    }

    &[class*="-down"] {
        transform: rotate(135deg);
    }
}

[class*="icono-arrow4"] {
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top: 7px solid;
    border-right: 7px solid;
    margin: 15px;

    &[class*="-left"] {
        transform: rotate(45deg);

        &[class*="-up"] {
            transform: none;
        }

        &[class*="-down"] {
            transform: rotate(90deg);
        }
    }

    &[class*="-right"] {
        transform: rotate(-135deg);

        &[class*="-up"] {
            transform: rotate(-90deg);
        }

        &[class*="-down"] {
            transform: rotate(180deg);
        }
    }

    &[class*="-up"] {
        transform: rotate(-45deg);
    }

    &[class*="-down"] {
        transform: rotate(135deg);
    }
}

.btn-outline-secondary:hover [class*="icono-arrow2"], .btn-outline-secondary:active [class*="icono-arrow2"], .btn-outline-secondary:focus [class*="icono-arrow2"] {
    color: $white;
}

//   [class*="icono-arrow2"]::after
//   {
//     content: '\25ba';
//     transform: rotate(45deg);
//   }
.icono-arrow2-left {
    margin-top: 6px;
}

.event-body {
    [class*="icono-arrow2"] {
        border-width: 4px;
    }

    [class*="icono-arrow2"]::before {
        width: 10px;
    }

    .btn-outline-secondary {
        padding-top: 9px;
    }
}

.news-details, .thanku-section {
    [class*="icono-arrow2"] {
        margin-top: 5px;
    }

    .btn-outline-primary:hover .icono-arrow2-right, .btn-outline-primary:active .icono-arrow2-right, .btn-outline-primary:focus .icono-arrow2-right {
        color: $white;
    }
}

// Custom File Upload

.custom-file, .custom-file-input {
    position: relative;
    width: 100%;
}

.custom-file-input {
    z-index: 2;
    margin: 0;
    opacity: 0;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "UPLOAD";
}

.custom-file-label {
    margin: 0;
    max-width: 100%;
    width: calc(100% - 30px);
}

.custom-file-input ~ .custom-file-label::after {
    content: "UPLOAD";
    background: #df9f9d;
    color: #fff;
    border-radius: 0;
}

.custom-file-input:focus ~ .custom-file-label::after {
    border-color: transparent !important;
}


// Custom Dropdown


















@media screen and (max-width: 575px) {
    [class*="icono-arrow2"] {
        border-width: 4px;
    }
    .icono-arrow2-left {
        margin-top: 10px;
    }    
}
