// Recruit a Nanny

.recruit-form {
    padding-top: 40px;
    padding-bottom: 120px;
    .row 
    {
        margin-left: 0;
        margin-right: 0;
    }
    .btn-primary {
        margin-top: 22px;
        margin-bottom: 43px;
    }

    .accordion {
        background-color: $accordian;
        border-radius: 15px;
        font-size: 20px;
        color: $white;
        padding: 30px;
        width: 100%;
        text-align: left;
        position: relative;
        cursor: pointer;
        outline: 0;
        border: 0px;
        overflow: hidden;
        margin-bottom: 22px;

        span {
            height: 100%;
            padding: 33px;
            background-color: $magenta;
            color: $white;
            margin-left: -30px;
            font-weight: 700;
            margin-right: 38px;
        }
    }

    .panel {
        padding: 30px 0px;
        display: none;
        background-color: transparent;
        // overflow: hidden;
        position: relative;
    }
    .custom-file-label {
        width: calc(100% - 15px);
    }
    h2 {
        margin-bottom: 27px;
    }

    h4 {
        font-size: 27px;
        margin-bottom: 14px;
    }

    .right-part {
        position: absolute;
        right: 38px;
        top: 33px;
        color: $white;
        font-size: 24px;
    }

    label {
        font-size: 16px;
    }

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        background: $dark;
        color: $white;
    }

    .nav-item {
        a {
            padding: 11px 29px;
            color: $dark;
            font-size: 18px;
            background-color: #f4f4f4;
            border-radius: 6px;
            margin-right: 17px;
            position: relative;
        }

        // a:active, a.active, a:focus {
        //     background: $dark;
        //     color: $white;
        // }

        a.active::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 45%;
            margin-top: 0px;
            border-left: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 10px solid $dark;
            outline: 0;
            transition: all .1s ease;
        }
    }

    .menu-panel {
        display: inline-flex;

        a {
            padding: 11px 29px;
            color: $dark;
            font-size: 18px;
            background-color: #f4f4f4;
            border-radius: 6px;
            margin-right: 17px;
            position: relative;
        } 
     
       a:hover, a:active, a.active, a:focus {
            background: $dark;
            color: $white;
        }

        a:active::after, a:focus::after, a.active::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 45%;
            margin-top: 0px;
            border-left: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 10px solid $dark;
            outline: 0;
            transition: all .1s ease;
        }
    }

    .back-btn {
        position: absolute;
        right: 0;
        top: 43px;
        font-weight: 700;
        color: $dark;
        font-size: 18px;
    }

    .hdng {
        margin-top: 30px;
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid $dark;
        margin-bottom: 34px;
    }

    .radio-inline {
        display: inline-block;
        // margin-right: 30px;
    }

    .mgn-custom {
        margin-right: 10px;
        outline: 0;
    }

    .dsp-block {
        display: block;
        width: 100%;
    }
}

.form-control {
    height: 40px;
    background-color: #f6f6f6;
}

.form-control:focus {
    background-color: #f6f6f6;
}

.box-style {
    border-radius: 0;
    border: 0;
    font-size: 14px;
    margin-bottom: 23px;
}

.mgn-top-5 {
    margin-top: 5px;
}

.qn-section {
    padding-top: 13px;
    padding-bottom: 13px;
    border-top: 1px solid $dark;
}

.mgn-top-40 {
    margin-top: 40px;
}

.p-l-15 {
    padding-left: 15px;
}

.mgn-btm-15 {
    margin-bottom: 15px;
}

.content-row {
    padding-bottom: 25px;
    border-bottom: 1px solid $dark;
    margin-left: 0;
    margin-right: 0;

    .col-sm-7 {
        padding-left: 0;
    }

    .col-sm-5 {
        padding-right: 0;
    }

    ul {
        padding: 0;
        display: flex;
        margin: 0;
        width: 100%;

        li {
            width: 20%;
        }

        input {
            position: absolute;
        }
    }

    .btn-option {
        position: relative;
        margin: 0;
        min-width: inherit;
        white-space: initial;
        display: table-cell;
        vertical-align: middle;
        line-height: 1.2em;
        width: 500px;
        min-width: 100%;
    }

    .question {
        display: block;
        margin-top: 21px;
        margin-bottom: 15px;
    }
}

.content-row ul li:last-child {
    margin-right: 0;
}

.content-row:last-child {
    margin-bottom: 40px;
}

.custom-file-label {
    left: 15px;
    top: inherit;
    height: 40px;
    background-color: #f6f6f6;
}

.custom-file-label, .custom-file-label::after {
    cursor: pointer;
    height: 40px;
}

.custom-file-label::after {
    background: $file_upload;
    color: #fff;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.set-2, .set-3, .set-4, .set-5 {
    .hdng {
        margin-bottom: 0;
    }
}
.custom-row-set
{
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}
// Nanny Enrollment

.enrollment-form {
    padding-top: 40px;
    padding-bottom: 50px;

    .content-section {
        ul {
            list-style: none;
            margin-left: 0;
            padding-left: 24px;
            counter-reset: line;

            li {
                position: relative;
                padding-left: 10px;
            }

            li:before {
                position: absolute;
                left: -25px;
                display: inline-block;
                width: 11px;
                height: 11px;
                border: 2px solid #980747;
                margin-right: .5em;
                margin-top: 6px;
                background-color: transparent;
                -webkit-border-radius: 50%;
                border-radius: 50%;
                text-align: center;
                line-height: 1.25em;
                content: "";
            }
        }

        img {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 25px;
        }

        .box-style {
            margin-bottom: 20px;
        }
    }

    .form-section {
        p {
            margin-bottom: 27px;
        }

        h3 {
            margin-bottom: 11px;
        }
    }

    .general-info {
        margin-top: 57px;

        .join-txt-area {
            height: 112px;
            margin-bottom: 27px;
        }

        .qa-txt-area {
            height: 108px;
            margin-bottom: 0;
        }

        p {
            font-size: 14px;
        }

        button {
            margin-top: 33px;
        }
    }
}

.txt-area {
    height: 195px;
    background-color: #F6F6F6;
    border: 0px;
    border-radius: 0px;
    margin-bottom: 20px;
    font-size: 14px;
    resize: none;
}

.corporate-form {
    padding-top: 40px;
    padding-bottom: 50px;
}

.training-form {
    padding-top: 40px;
    padding-bottom: 50px;
}

@media screen and (max-width: 575px) {
    .recruit-form {
        .accordion {
            padding: 20px 10px;
            font-size: 16px;
            margin-bottom: 15px;

            span {
                padding: 23px 10px;
                margin-right: 10px;
                margin-left: -10px;
            }

            .right-part {
                right: 11px;
                top: 26px;
                font-size: 14px;
            }
        }

        .chiller_cb {
            display: none;
        }

        .nav-item {
            width: 100%;

            a {
                width: 100%;
                margin-bottom: 15px;
            }

            a:after {
                display: none;
            }
        }

        label {
            font-size: 14px;
        }

        .custom-file-input {
            height: inherit;
            margin-bottom: 38px;
        }

        .custom-file {
            height: inherit;
        }

        .panel {
            padding: 0;
        }
        .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, 
        .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
            margin: 0;
            padding: 0;
        }
    }

    .content-row {
        padding-bottom: 5px;

        .col-sm-7 {
            padding-right: 0;
        }

        .col-sm-5 {
            padding-left: 0;
        }

        .btn-option {
            margin-bottom: 10px;
            font-size: 14px;
        }
    }

    .enrollment-form {
        padding-top: 15px;
        padding-bottom: 15px;

        img {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        ul {
            font-size: 14px;
        }

        .form-section {
            p {
                margin-bottom: 15px;
            }
        }

        .txt-area {
            margin-bottom: 10px;
        }

        .customFile label {
            margin-top: 0;
        }

        .custom-file-input {
            margin-bottom: 27px !important;
        }

        .general-info {
            margin-top: 15px;

            .join-txt-area {
                margin-bottom: 15px;
            }

            .qa-txt-area {
                margin-bottom: 15px;
            }

            button {
                margin-top: 15px;
            }
        }
    }

    #main-wrapper #main-header + main.qntabanner {
        padding-bottom: 20px;
    }

    .box-style {
        margin-bottom: 15px !important;
    }

    .content-row {
        ul {
            flex-flow: wrap;

            li {
                width: 100%;
                margin-right: 0px;
                margin-bottom: 15px;

                .btn-option {
                    margin: 0;
                }
            }
        }
    }

    .recruit-form .back-btn {
        position: relative;
        bottom: inherit;
        top: inherit;
        margin-bottom: 15px;
    }

    .set-2 .content-row li {
        margin-right: 0 !important;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .enrollment-form {
        padding-top: 15px;
        padding-bottom: 15px;

        img {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .chiller_cb {
            display: none;
        }

        .col-sm-4 {
            padding-right: 0;
        }

        .col-sm-4:last-child {
            padding-right: 15px;
        }

        ul {
            font-size: 14px;
        }

        .form-section {
            p {
                margin-bottom: 15px;
            }
        }

        .txt-area {
            margin-bottom: 10px;
        }

        .customFile label {
            margin-top: 0;
        }

        .custom-file-input {
            margin-bottom: 27px !important;
        }

        .general-info {
            margin-top: 15px;

            .join-txt-area {
                margin-bottom: 15px;
            }

            .qa-txt-area {
                margin-bottom: 15px;
            }

            button {
                margin-top: 15px;
            }
        }

        .box-style {
            margin-bottom: 15px !important;
        }
    }

    #main-wrapper #main-header + main.qntabanner {
        padding-bottom: 20px;
    }

    .recruit-form {
        .nav-item {
            a {
                margin-right: 3px;
            }
        }
    }

    .content-row {
        .btn-option {
            height: 50px !important;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .content-row {
        .btn-option {
            min-width: auto;
            font-size: 11px;
        }

        .col-sm-4 {
            padding-right: 0;
        }

        .col-sm-6:last-child {
            padding-left: 0;
        }

        .btn-option {
            white-space: inherit;
        }
    }

    .radio-inline {
        white-space: nowrap;
        outline: 0;
    }

    h3 {
        font-size: 24px;
    }

    .content-row {
        .btn-option {
            height: 50px !important;
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .content-row {
        .btn-option {
            min-width: auto;
            font-size: 13px;
        }

        .col-sm-4 {
            padding-right: 0;
        }

        .col-sm-6:last-child {
            padding-left: 0;
        }

        .btn-option {
            white-space: inherit;
        }
    }

    .enrollment-form {
        padding-top: 15px;
        padding-bottom: 15px;

        img {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .col-sm-4 {
            padding-right: 0;
        }

        .col-sm-4:last-child {
            padding-right: 15px;
        }

        ul {
            font-size: 14px;
        }

        .form-section {
            p {
                margin-bottom: 15px;
            }
        }

        .txt-area {
            margin-bottom: 10px;
        }

        .customFile label {
            margin-top: 0;
        }

        .custom-file-input {
            margin-bottom: 27px !important;
        }

        .general-info {
            margin-top: 15px;

            .join-txt-area {
                margin-bottom: 15px;
            }

            .qa-txt-area {
                margin-bottom: 15px;
            }

            button {
                margin-top: 15px;
            }
        }

        .box-style {
            margin-bottom: 15px !important;
        }

        h3 {
            font-size: 24px;
        }
    }

    #main-wrapper #main-header + main.qntabanner {
        padding-bottom: 20px;
    }

    .radio-inline {
        white-space: nowrap;
    }
}

@media screen and (min-width: 576px) and (max-width: 991px) {
    .recruit-form {
        label {
            span {
                display: none;
            }
        }
    }

    .enrollment-form {
        padding-top: 15px;
        padding-bottom: 15px;

        img {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .col-sm-4 {
            padding-right: 0;
        }

        .col-sm-4:last-child {
            padding-right: 15px;
        }

        .form-section {
            p {
                margin-bottom: 15px;
            }
        }

        .txt-area {
            margin-bottom: 10px;
        }

        .customFile label {
            margin-top: 0;
        }

        .custom-file-input {
            margin-bottom: 27px !important;
        }

        .general-info {
            margin-top: 15px;

            .join-txt-area {
                margin-bottom: 15px;
            }

            .qa-txt-area {
                margin-bottom: 15px;
            }

            button {
                margin-top: 15px;
            }
        }

        .box-style {
            margin-bottom: 15px !important;
        }
    }

    #main-wrapper #main-header + main.qntabanner {
        padding-bottom: 20px;
    }
}
