.contact_us
{
    padding-top: 90px;
    padding-bottom: 60px;
    img 
    {
        width:100%;
        margin-bottom: 40px;
    }
    p 
    {
        text-align: center;
    }
}
.form-container 
{
    padding-left: 80px;
    padding-right: 80px;
}

@media screen and (max-width:767px)
{
        .form-container 
    {
        padding: 0;
    }
}