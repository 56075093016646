@font-face {
    font-family: 'Trajan Pro';
    src: url('#{$font-path}/TrajanPro-Regular.woff2') format('woff2'),
        url('#{$font-path}/TrajanPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html{
	font-size: $font-size-root
} 