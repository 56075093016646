// Media News & Presss Releases Page
a {
    transition: all 1s ease;
    outline: 0;
}


.pagination {
    list-style: none;
    float: right;
    margin-right: -15px;

    li {
        display: inline;

        button {
            width: 25px;
            height: 25px;
            background-color: #f6f6f6;
            color: $txt-color;
            border-radius: 50%;
            margin-right: 5px;
            font-size: 14px;
            font-weight: 500;
            border: none;
            cursor: pointer;
            transition: all 1s ease;
        }

        button:hover, button:active, button:focus {
            background-color: #CA868C;
            color: $white;
        }
    }

    li:first-child {
        button {
            width: 0;
            height: 0;
            border-top: 13px solid transparent;
            border-right: 20px solid $magenta;
            border-bottom: 13px solid transparent;
            background-color: transparent;
            border-radius: inherit;
            color: transparent;
            font-size: 5px;
        }

        button:hover, button:active, button:focus {
            border-right: 20px solid $dark;
        }
    }

    li:last-child {
        button {
            width: 0;
            height: 0;
            border-top: 13px solid transparent;
            border-left: 20px solid $magenta;
            border-bottom: 13px solid transparent;
            background-color: transparent;
            border-radius: inherit;
            margin-right: 0;
            color: transparent;
            font-size: 5px;
        }

        button:hover, button:active, button:focus {
            border-left: 20px solid $dark;
        }
    }
}

.media_news {
    padding-top: 60px;
    padding-bottom: 60px;

    .slider-content {
        background-color: $white;
        color: $dark;
        box-shadow: 0 3px 7px 0 rgba(206, 206, 206, 0.5);
        border: 1px solid #E6E6E6;
        max-height: 240px;
        margin-bottom: 30px;

        .txt-white {
            max-height: 77px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .slider-content:hover {
        color: $white;
    }

    .event-date {
        border-bottom: 1px solid $dark;
    }

    .read-more {
        color: $dark;
        font-weight: 700;
    }

    .news-cnt:hover {
        .event-date {
            border-bottom: 1px solid $white;
        }

        .read-more {
            color: $white !important;
        }

        .col-lg-6:nth-child(1) {
            padding-left: 0;
        }

        .col-lg-6:nth-child(2) {
            padding-right: 0;
        }
    }
}

.tab-btn {
    a {
        padding: 12px 30px 10px 30px;
        margin-right: 20px;
        display: inline-flex;
        background-color: #f4f4f4;
        border-radius: 6px;
        font-size: 20px;
        cursor: pointer;
        position: relative;
        font-family: 'Trajan Pro';
        font-weight: 700;
        outline: 0;
        .svg-align
        {
            margin-right: 10px;
        }
    }

    a:hover, a:active, a:focus, a.active {
        background-color: $magenta;
        color: $white;
        outline: 0;
    }
    a:hover svg, a:active svg, a:focus svg, a.active svg {
        fill: $white;
        transition: all 1s ease;
    }
    a:active::after, a:focus::after, a.active::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 43%;
        margin-top: 0px;
        border-left: 0;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-top: 10px solid $magenta;
        outline: 0;
        transition: all .1s ease;
    }
}

// Media Events Page

.media_events {
    padding-top: 60px;
    padding-bottom: 60px;

    .events-list-wrapper {
        margin-top: 60px;
        margin-bottom: 12px;
    }

    .date {
        margin-right: 34px;
    }

    img {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    h6 {
        font-family: "Montserrat",sans-serif;
        color: $txt-color;
        font-weight: 500;
    }

    .btn-outline-secondary {
        width: 163px;
        height: 40px;
        font-size: 14px;
        min-width: inherit;
    }

    .location-section {
        float: right;
        margin-top: 5px;

        .map-pin {
            font-size: 23px;
            color: $dark;
            margin-right: 13px;
        }

        p {
            text-align: right;
            float: right;
            margin: 0;
        }
    }
}

.pull-right {
    float: right;
}

//Media Gallery Page

.media_gallery {
    padding-top: 60px;
    padding-bottom: 60px;
    margin: 0 !important;
    .gallery-wrapper {
        margin-top: 60px;
    }

    .slide-img-wrap {
        height: 300px;
        margin-bottom: 30px;
    }

    .slides {
        padding: 0;
        cursor: pointer;
    }
}

// News Details Page

.news-details {
    padding-top: 60px;
    padding-bottom: 110px;

    .detail-wrapper {
        margin-top: 60px;
        margin-bottom: 43px;
        padding: 34px 50px;
        border: 1px solid #E6E6E6;
        box-shadow: 0 2px 5px 0 rgba(206, 206, 206, 0.5);

        a {
            font-family: "Montserrat",sans-serif;
            color: $dark;
            margin: 0;
            float: right;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    h6 {
        font-family: "Montserrat",sans-serif;
        color: $dark;
        font-weight: 600;
        margin: 0;
        float: left;
        font-size: 20px;
    }

    .heading {
        padding-bottom: 21px;
        border-bottom: 1px solid #d8d8d8;
        width: 100%;
    }

    h2 {
        margin-top: 27px;
    }

    img {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}

// Media Image Slider

.media-slider {
    .row > .column {
        padding: 0 8px;
    }

    .row:after {
        content: "";
        display: table;
        clear: both;
    }

    .column {
        float: left;
        width: 25%;
    }

    /* The Modal (background) */
    .modal {
        display: none;
        position: fixed;
        z-index: 1;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: black;
    }

    /* Modal Content */
    .modal-content {
        position: relative;
        background-color: #fefefe;
        margin: auto;
        padding: 0;
        width: 90%;
        max-width: 1200px;
    }

    /* The Close Button */
    .close {
        color: white;
        position: absolute;
        top: 10px;
        right: 25px;
        font-size: 35px;
        font-weight: bold;
    }

    .close:hover,
    .close:focus {
        color: #999;
        text-decoration: none;
        cursor: pointer;
    }

    .mySlides {
        display: none;
    }

    .cursor {
        cursor: pointer;
    }
}

// Media Newsletter

.media_newsletter 
{
    margin-bottom: 90px;
    .nletter 
    {
        
        margin-bottom: 30px;
    }
    .nl-img img
    {
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 3px 7px 0 rgba(206,206,206,.5);
    }
    .txt-section h6
    {
        text-align: center;
        font-family: "Montserrat",sans-serif;
        margin-bottom: 20px;
        font-size: 18px;
    }
    .btn-outline-secondary
    {
        width: 163px;
        height: 40px;
        font-size: 14px;
        min-width: inherit;
    }
    .btn-section 
    {
        margin: auto;
        text-align: center;
    }
}
@media screen and (max-width: 615px) {
    .media_news, .media_events, .media_gallery {
        padding-top: 30px;
        padding-bottom: 30px;

        .slider-content {
            margin-bottom: 15px;
        }

        .pagination {
            display: flex;
            justify-content: center;
            float: none;
        }

        .events-slides {
            margin-top: 15px;
        }
    }

    .tab-btn {
        a {
            display: inline-flex;
            width: auto;
            margin-bottom: 15px;
            padding: 15px;
            padding-bottom: 10px;
            margin-right: 15px;
            .svg-label 
            {
                display: none;
            }
            .svg-align 
            {
                margin-right: 0;
            }
        }
    }
    .tab-btn a.active::after, .tab-btn a:active::after, .tab-btn a:focus::after {
        left: 30%;
    }
    .media_events {
        .events-list-wrapper {
            margin-top: 15px;
        }
    }

    .media_gallery {
        .gallery-wrapper {
            margin-top: 15px;
        }

        .slides {
            margin-bottom: 15px;
        }

        .slide-img-wrap {
            margin-bottom: 15px;
        }
    }

    .news-details {
        padding-top: 0px;
        padding-bottom: 70px;

        .detail-wrapper {
            margin-top: 15px;
            padding: 15px;
            margin-bottom: 15px;
        }

        img {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }
    .media_newsletter 
    {
        .nl-img img, .txt-section h6
        {
            margin-bottom: 15px;
        }
    }
}

@media screen and (min-width: 616px) and (max-width: 767px) {
    .tab-btn {
        a {
            font-size: 16px;
            margin-bottom: 15px;
        }
    }

    .media_gallery {
        .slider-content {
            width: calc(100% - 30px);
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .media_news, .detailed {
        padding-top: 60px;
        .container 
        {
            padding-left: 49px;
            padding-right: 49px;
        }
    }

    .tab-btn {
        a {
            padding: 12px 30px 10px;
            margin-right: 15px;
            font-size: 18px;
        }
    }
        .col-lg-6:nth-child(odd) {
            padding-right: 0;
            

        .slider-content {
            margin-bottom: 0;
        }
    }

    .media_gallery {
        padding-bottom: 30px;

        .slider-content {
            width: calc(100% - 30px);
        }
        .slides {
            padding: 0 !important;
        }
    }
    
    .news-details {
        padding-top: 0px;
        padding-bottom: 90px;

        .detail-wrapper {
            margin-top: 35px;
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: 550px) {
    .media_events {
        .location-section {
            float: left;

            .map-pin {
                float: left;
            }

            p {
                float: none;
                text-align: left;
            }
        }
    }
}
