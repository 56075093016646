.parent-info 
{
	padding-top: 25px;
	.content 
	{
		-webkit-box-align: inherit;
		-webkit-align-items: inherit;
		-ms-flex-align: inherit;
		align-items: inherit;
		padding-top: 30px;
	}
	ul 
	{
		list-style: none;
		margin-left: 1em;
		counter-reset: line;
		li
		{
			position: relative;
			margin-bottom: 30px;
		}
		li:before {
			position: absolute;
			left: -26px;
			display: inline-block;
			width: 11px;
			height: 11px;
			border: 2px solid #980747;
			margin-right: .5em;
			margin-top: 6px;
			background-color: transparent;
			-webkit-border-radius: 50%;
			border-radius: 50%;
			text-align: center;
			line-height: 1.25em;
			content: "";
		}
	}
	h2 
	{
		font-size: 27px;
	}
}


// Accordian Section
.bg-white 
{
	background-color: #f1f1f1;
	opacity: 0.9 !important;
}
.parents-accordian
{
	padding-top: 80px;
	padding-bottom: 130px;
	position: relative;
	.accordion 
	{
		background-color: $white;
		color: $txt-color;
		cursor: pointer;
		padding: 15px 60px 15px 26px;
		width: 100%;
		text-align: left;
		outline: none;
		font-size: 20px;
		transition: 0.4s;
		border: 1px solid #E6E6E6;
		border-radius: 2px;
		box-shadow: 0 2px 5px 0 rgba(206,206,206,0.5);
		margin-bottom: 13px;
		position: relative;
	}
	.btn
	{
		background-color: $white;
	}
	.active, .accordion:hover {
		background-color: #f8f8f8; 
	}
	
	.panel {
		padding: 0 18px;
		display: none;
		background-color: transparent;
		overflow: hidden;
	}
	h2 
	{
		margin-bottom: 27px;
		font-size: 27px;
	}
	.right-part 
	{
		position: absolute;
		right:26px;
		top:22px;
		color: $dark;
		font-size: 18px;
		background-color: transparent;
	}
	.btn-outline-secondary
	{
		margin-top: 19px;
	}
}
.right-align 
{
	float: right;
}

// Selection Content
.selection-content 
{
	padding-top: 63px;
	padding-bottom: 63px;
	position: relative;
	h3 
	{
		margin-bottom: 17px;
	}
	.slider-section 
	{
		padding: 27px 35px;
		img 
		{
			width: 100%;
		}
	}
	.slider-cnt 
	{
		padding: 42px 30px;
		overflow: hidden;
		position: relative;
		
		ul {
			list-style: none;
			
			counter-reset: line;
	
			li {
				position: relative;
				margin-bottom: 21px;
			}
	
			li:before {
				position: absolute;
				left: -25px;
				display: inline-block;
				width: 11px;
				height: 11px;
				border: 2px solid #980747;
				margin-right: .5em;
				margin-top: 6px;
				background-color: transparent;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				text-align: center;
				line-height: 1.25em;
				content: "";
			}
		}
	}
	.slider-main 
	{
		background-color: $white;
		border: 1px solid #E6E6E6;
		
	}
	.col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, 
	.col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, 
	.col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, 
	.col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, 
	.col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7,
	 .col-xl-8, .col-xl-9, .col-xl-auto {
		padding: 0;
	 }
	 
	h5 
	{
		font-size: 20px;
		margin-bottom: 25px;
	}
	.slick-dots 
	{
			position: absolute;
			bottom: 32px;
			width: inherit;
			padding: inherit;
			margin: inherit;
			list-style: none;
			text-align: inherit;
			right: 40px;
	}
	.media-hide 
	{
		overflow: hidden;
	}
	.row 
	{
		margin: 0;
	}
	.slick-prev, .slick-next {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		display: block;
		width: 25px;
		height: 50px;
		padding: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		cursor: pointer;
		color: transparent;
		border: none;
		outline: none;
		background: transparent;
		
	}
	.slick-prev 
	{
		left: -35px;
	}
	.slick-prev::before {
		content: ' \276E';
	}
	.slick-prev::before, .slick-next::before {
		font-family: 'slick';
		font-size: 50px;
		line-height: 1;
		opacity: .75;
		color: $dark;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.slick-next
	{
		right:-33px;
	}
	.slick-next::before {
		content: ' \276F';
	}
}
.parent-info 
{
	.content 
	{
		min-height: inherit;
		padding: 0;
	}
}










@media screen and (max-width:575px)
{
	.parent-info
	{
		padding-top: 0;
		.content 
		{
			padding-top: 0;
		}
		ul 
		{
			font-size: 14px;
			margin-left: 0;
		}
	} 
	.parents-accordian 
	{
		padding-top: 15px;
		padding-bottom: 80px;
		.accordion
		{
			font-size: 14px;
			.right-part 
			{
				font-size: 14px;
				top:20px;
			}
		}
	}
	.selection-content 
	{
		padding: 30px 0px;
		.media-hide 
		{
			display: none;
		}
		p 
		{
			margin: 0;
		}
		ul 
		{
			font-size: 14px;
		}
		.slider-section 
		{
			padding: 30px 15px;
		}
		.slider-cnt
		{
			padding:30px 15px;
		}
		.slick-prev 
		{
			left: -25px;
		}
		.slick-next 
		{
			right:-25px;
		}
		.slick-dots
		{
			right:15px;
			bottom: 6px;
		}
	}
	h2 
	{
		font-size: 23px !important;
	}
}
@media screen and (min-width:576px) and (max-width:767px)
{
	.parents-accordian 
	{
		.accordion
		{
			font-size: 18px;
		}
	}
	.selection-content 
	{
		.media-hide 
		{
			display: none;
		}
	}
}
@media screen and (min-width:768px) and (max-width:991px)
{
	.parent-info {
		padding-top: 0px;
	}
	.parents-accordian
	{
		padding-top: 50px !important;
		padding-bottom: 130px;
		.container 
		{
			padding-left: 49px;
			padding-right: 49px;
		}
		.accordion 
		{
			font-size:18px;
		}
	}
	.selection-content 
	{
		.media-hide 
		{
			display: none;
		}
		.container 
		{
			padding: 0px 49px;
		}
	}
}