.padding-nannies {
    padding-top: 50px;
    min-height: inherit;

    .content {
        padding-top: 20px;
        min-height: inherit;
    }
}

.nannies-apply, .nannies-qnta {
    overflow: hidden;
}

.xtend {
    width: 100vw;
    left: 0;
    margin-left: 15px;
}

.xtend-rgt {
    width: 100vw;
    left: auto;
    right: 0;
    margin-right: 15px;
}

.nannies-apply {
    .row {
        margin: 0;
    }
}

.nannies-apply .content {
    padding-top: 60px;
    padding-bottom: 60px;
    text-align: center;

    h3 {
        color: $white;
        width: 100%;
    }

    button {
        margin: auto;
    }

    img {
        width: 100%;
    }
}

.nannies-training {
    padding-top: 60px;
    position: relative;

    h2 {
        margin-bottom: 25px;
    }

    img {
        margin-bottom: 31px;
        width: 100%;
    }

    .row {
        padding-left: 0 !important;
        margin: 0;
    }

    .col-lg-5 {
        padding-left: 0;
    }

    ul {
        list-style: none;
        
        counter-reset: line;

        li {
            position: relative;
            margin-bottom: 21px;
        }

        li:before {
            position: absolute;
            left: -25px;
            display: inline-block;
            width: 11px;
            height: 11px;
            border: 2px solid #980747;
            margin-right: .5em;
            margin-top: 6px;
            background-color: transparent;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            text-align: center;
            line-height: 1.25em;
            content: "";
        }
    }
}

.nannies-children {
    margin-top: 60px;
}

.nannies-children .content {
    padding-top: 60px !important;
    padding-bottom: 60px;
    text-align: center;

    h3 {
        color: $white;
        width: 100%;
    }

    button {
        margin: auto;
    }

    img {
        width: 100%;
    }
}

.nannies-cooperation .content {
    padding-top: 54px;
    padding-bottom: 54px;
    padding-left: 0px !important;

    img {
        margin-top: 20px;
        margin-bottom: 30px;
        width: 100%;
    }
}

.nannies-cooperation {
    .row {
        margin: 0;
    }
}

.nannies-qnta .content {
    padding-top: 60px;
    padding-bottom: 60px;
    background: url("../img/_DYL0230.jpg");
    text-align: center;

    h3 {
        color: $white;
        width: 100%;
    }

    button {
        margin: auto;
    }

    img {
        width: 100%;
    }
}

.nannies-qnta {
    .row {
        margin-left: 0;
    }
}

.qnta-background {
    .form-control {
        background-color: $white;
    }
}

@media screen and (max-width: $screen-sm) {
    .padding-nannies {
        padding-top: 0px !important;

        .content {
            padding: 15px;
        }

        img {
            margin-top: 15px;
        }
    }

    .nannies-apply .content, .nannies-children .content, .nannies-qnta .content {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    .nannies-training {
        padding-top: 15px;
        padding-bottom: 0;

        .col-lg-5, .col-lg-7 {
            padding: 0;
        }

        ul {
            padding-left: 24px;
            font-size: 14px;
        }

        img {
            margin-top: 0px;
            margin-bottom: 15px;
        }
    }

    h3 {
        font-size: 18px;
    }

    .nannies-cooperation {
        .content {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    .nannies-children {
        margin-top: 30px;
    }
}

@media screen and (min-width: 577px) and (max-width: 767px) {
    h1 {
        font-size: 27px;
    }

    h2 {
        font-size: 24px;
    }

    .padding-nannies {
        padding-top: 0px !important;

        .content {
            padding: 15px;
        }
    }

    .nannies-apply .content, .nannies-children .content, .nannies-qnta .content {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    .nannies-training {
        padding: 15px !important;

        .col-lg-5, .col-lg-7 {
            padding: 0;
        }

        ul {
            padding-left: 15px;
        }
    }

    h3 {
        font-size: 18px;
    }

    .nannies-cooperation {
        .content {
            padding: 15px !important;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .padding-nannies {
        padding-top: 0px !important;

        .content {
            padding-top: 40px;
            padding-bottom: 15px;
        }
    }

    .nannies-apply .content, .nannies-children .content, .nannies-qnta .content {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }
.nannies-cooperation 
{
	p 
	{
		margin: 0;
	}
}
    .nannies-training {
        .col-lg-5, .col-lg-7 {
            padding: 0;
        }

        ul {
            padding-left: 21px;
            margin-left: 0;
		}
		img 
		{
			margin-top: 19px;
		}
    }

    h3 {
        font-size: 18px;
    }
.nannies-children 
{
	margin-top: 40px;
}
    .nannies-cooperation, .nannies-training, {
        padding-top: 50px;

        .container {
            padding-left: 49px;
            padding-right: 49px;
        }
    }

    .xtend {
        margin-left: 0;
    }

    .xtend-rgt {
        margin-right: 0;
    }
    .nannies-training, .nannies-cooperation
{
	.content
	{
		padding-top: 0px;
	}
}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .padding-nannies {
        padding: 0 !important;
    }

    .nannies-training {
        .col-lg-5, .col-lg-7 {
            padding: 0;
        }
        h3 {
            font-size: 18px;
        }
    }

   
}

@media screen and (max-width: 575px) {
    .xtend {
        margin: 0;
    }

    .xtend-rgt {
        margin: 0;
    }
}
