.thanku-section 
{
    position: relative;
    padding-top: 60px;
    padding-bottom: 170px;
    text-align: center;
    .img-container 
    {
        width:370px;
        height: 370px;
        border-radius: 50%;
        overflow: hidden;
        margin: 37px auto;
        img 
        {
            overflow: hidden;
        }
    }
    .btn-section 
    {
        display: flex;
        justify-content: flex-end;
    }
    .download-section 
    {
        margin: auto;
        padding-top: 35px;
        p 
        {
            display: contents;
        }
    }
}
@media screen and (max-width:575px)
{
    .thanku-section 
    {
        padding-bottom: 20px;
        .img-container
        {
            width:200px;
            height:200px;
            img 
            {
                width: 150%;
            }
        }
        .download-section 
    {
        padding-top: 15px;
        p 
        {
            display: block;
           margin-bottom: 15px;
        }
    }
    }
}
@media screen and (min-width:576px) and (max-width:767px)
{
    .thanku-section 
    {
        padding-bottom: 20px;
    }
}