// Social media icons
.social-icons{
	display: flex;
	& > a{
	    background: theme-color('secondary');
	    border-radius: 50%;
	    display: flex;
	    width: 29px;
	    height: 29px;
	    align-items: center;
	    justify-content: center;
	    &:not(:last-child){
	    	margin-right: 15px;
	    }
	}
	i{
		color: $white;
	}
}


.who-we-are{
	.bg-img{
		background-image: url('../img/who-we-are1.png');
	}
	.content{
		min-height: 450px; 
		height:auto !important; 
		height: 450px; 

		padding: 15px;
		padding-left: 0px !important;
		display: flex;
		align-items: center; 
	}
	.btn{
		float: right;
	}
}

.chat{
	position: relative;
	height: 800px;
	display: flex;
	align-items: center;
	.chat-wrapper{
		display: flex;
		justify-content: flex-end;
	}
	.chat-body{
		display: flex;
		justify-content: space-between;
		&:not(:last-child){
			margin-bottom: 20px;
		}
	}
	figure{
		width: 63px;
		height: 63px;
		background-size: contain;
		background-position: center;
		border-radius: 50%;
		box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.2);
		background-repeat: no-repeat;
		background-color: $white;
	}
	.ch-usr
	{
		figure{
			background-size:cover; 
		}
			
	}
	.ch-txt{
		width: calc(100% - 75px);
		border-radius: 40px;
		padding: 32px 39px;
		background-color: $white;
		font-size: 20px;
		line-height: 24px;
	}
}
.ch-usr{
	align-items: center;
	.ch-txt{
		background-color: theme-color('secondary');
		color: $white;
	}
}

.what-we-do{
	padding-top: 100px;
	padding-bottom: 80px;
	overflow: hidden;
	position: relative;
	min-height: 746px;
	height: auto !important;
	height: 750px;
	
	.content.content-main{
		z-index: 3;
		color: $white;
		margin-bottom: 60px;
	}
	.overlayed-sec{
		z-index: 4;
		position: relative;
		figure{

			width: 100%;
			height: 490px;
			left: -15px;
			position: absolute;
			top: -30px;
		}
		.btn{
			float: right;
		}
	}
	.txt-white 
	{
		color:$white;
	}
}
.content-block{
	background-color: $white;
	padding: 60px;
	&.left-block{
		padding-left: 0;
		position: relative;
		&::before{
			content: "";
			position: absolute;
			background: $white;
			height: 100%;
			width: 100vw;
			right: 100%;
			top: 0;
		}		
	}
	&.top-block{
		width: 90%;
	    right: 15px;
	    top: 0;
	    transform: translateY(-50%);
	    position: absolute;
	}
	.title{
		position: relative;
		padding-top: 25px;
		&::before{
			content: "";
			position: absolute;
			top: 0;
			width: 60px;
			left: 0;
			background-color: #ca868c;
			height: 4px;
		}
	}
}

.gallery{
	.slider{
		margin-bottom: 0;
	}
	.slides{
		// padding: 0 15px;
		padding: 0 20px;
		// width: 400px;
	}
	.slide-img-wrap{
		position: relative;
		// width: 400px;
		width: 100%;
	    height: 490px;
	    background-size: cover;
	    background-position: center center;
	    // &::before{
	    // 	content: "";
		   //  position: absolute;
		   //  top: 0;
		   //  left: 0;
		   //  width: 100%;
		   //  height: 100%;
		   //  background: rgba(0, 0, 0, 0.3);
	    // }
	    // &:hover::before{
	    // 	background: rgba(121,15,49,.9);
	    // }
	    .mask{
	    	transition: all .4s ease;
	    }
	    &:not(:hover){
	    	.bg-image{
			    filter: grayscale(100%);
	    	}
	    	.mask{
	    		background-color: rgba(0, 0, 0, 0.3)!important;
			    opacity: .3;
	    	}
		}
		&:hover{
			.slider-content{
				& > p{
					max-height: 140px;
				}
			}
		}
	}
	.slider-content{
		position: absolute;
	    left: 0;
	    width: calc(100% - 70px);
	    right: 0;
	    margin: auto;
	    bottom: 14px;
	    color: $white;
	    & > p{
	    	margin-top: 10px;
	    	margin-bottom: 0;

			max-height: 0;
			overflow: hidden;
			transition: all 1s ease;			
	    }
	    h4{
	    	margin: 0;
	    }
	}
}
.title-with-btn{
	padding: 50px 0;
	display: flex;
	justify-content: space-between;
}

.events{
	.date{
		padding-right: 25px;
	    margin-right: 25px;
	    border-right: 1px solid $gray-200;
		span{
			display: block;
			white-space: nowrap;
			&.month{
				font-weight: 600; 
				font-size: 20px;
				line-height: 37px;
			}
			&.year{
				font-size: 30px;
			}
		}
	}
	.read-more{
		float: right;
		font-weight: 700;
		font-size: 15px;
	}
}
.events-list-wrapper{
	display: flex;
	.btn{
		height: auto;
		width: 150px;
		margin-bottom: 10px;
	}
}
.events-slides{
	margin-top: 50px;
	display: flex;
	.slick-slide{
		padding: 0 12px;
		&:nth-child(2){
			@include media-breakpoint-up(lg) {
                padding: 0;
            }
		}
	}
	.slides{
		// padding: 0 10px;
		position: relative;
		&:hover .slider-content{
			background-color: transparent;
		}
	}
	.slides:nth-child(even){
		@include media-breakpoint-up(lg) {
			padding: 0;
		}
	}
	figure{
		height: 330px;
		background-size: cover;
		background-position: center center;
	}
	.slider-content{
			background-color: #7c6568;
			padding: 35px;
			height: 330px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			color: $white; 
			transition: all 1s ease;
	}
	.read-more{
		align-self: flex-end;
		color: $white;
	}
	.event-date{
		font-size: 20px;
		padding-bottom: 10px;
		margin-bottom: 25px;
		border-bottom: 1px solid $white;
		font-weight: 600;
		line-height: 24px;
		// color: $white;
	}
}

.testimonials{
	padding: 80px 0;
	background: url("../img/strip.png");
	text-align: center;
	.container{
		max-width: 660px;
	}
	.slider{
		padding-bottom: 30px;
		margin-bottom: 20px;
	}
	.user-pic{
		width: 150px;
		height: 150px;
		background-size: cover;
		background-position: center;
		border-radius: 50%;
		margin: auto;
		border: 5px solid #fff;
    	box-shadow: 0px 2px 4px 2px #e9ecef;
		margin-top: 25px;
		margin-bottom: 23px;
	}
	.user-name{
		font-weight: 600;
		font-size: 18px;
		color: #262626;
		margin-bottom: 20px;
	}
}

.map{
	position: relative;
	height: 400px;
	.address-wrapper{
		position: absolute;
	    top: 50%;
	    transform: translateY(-50%);
		left: 0px;
		right: 0;
	}
	.address-block{
		display: inline-block;
		background-color: $white;
		padding: 35px;
		pointer-events: none;
		span{
			display: block;
		}
		.po-box{
			color: #7C6568;
			margin-bottom: 10px;
			margin-top: 10px;
			font-size: 16px;
			font-weight: 600;
		}
	}
}

// Nanny form
.nanny-form{
	background: $white;
	padding: 35px;
	position: fixed;
	top: 0;
	right: -100%;
	z-index: 99;
	width: 735px;
	height: 100vh;
	transition: all .8s ease;
	.form-close{
		height: auto;
		width: auto;
		min-width: auto;
		background: no-repeat;
		outline: none;
		border: none;
		box-shadow: none;
		float: right;
		i{
			padding-right: 7px;
		}
	}
	.form-wrapper{
		max-width: 450px;
		height: 100%;
		.mCSB_container
        {
            padding-left: 5px;
        }
	}
	.custom-file-label{
		margin: 0;
		max-width: 100%;
		width: calc(100% - 30px);
		left: 15px;
		height: 31.5px;
		&::after{		
			background: #df9f9d;
		    color: #fff;
		    border-radius: 0;
		}
	}
	.custom-file-input:lang(en)~.custom-file-label::after{
		content: "UPLOAD";
	}
	form{
		.btn{
			float: right;
		}
	}

	.spouse-detail{
		margin-top: 30px;
	}
}
.sh-form {
	.nanny-form{
		right: 0;
	}
}


// About QNTA
.about{
	.container{
		padding: 50px 0px;
		.about-container{
			padding: 0px 20px 30px 20px;
			
		}
		figure .img-fluid{
			width: 100%;
		}
	}
}

.about-nannies{
	height:600px;
	.background{
		left: auto;
		top: auto;
	}

	.container{
		height:100%;
		.d-flex{
			display: flex;
		}
	}

	.content-block{
		justify-content: space-between;
		flex-direction: column;
		display: flex;
		text-align: center;
		text-align: -webkit-center;
			.title{
			&::before{
				display: none;
			}
		}
	}

	.overlayed-sec {
		padding-top: 10%; 
		.col-md-6:nth-child(2){
			.sub-title{
				color:white;
			}
			.content-block{
				background-color: transparent;
				color: $white;
			}
		}
	}
}
