body{
	// color: $default-color;
	padding-top: 170px;
}
a{
	color: $dark; 
	&:hover{
		text-decoration: none;
	}	
}
figure{
	margin-bottom: 0;
}
.p-l-0{
	padding-left: 0;
}
.p-r-0{
	padding-right: 0;
}
.title{
	text-transform: uppercase;
}

.v-all-btn-mob{
    margin: auto;
    display: block;
    margin-top: 20px;
}

.sec-wrapper{
	display: flex;
	flex-wrap: wrap;
}

.bg-img{
	background-size: cover;
	background-position: center center;
}

.slick-dots {
	li {
		button{
			&:before{
				font-size: 48px;
				font-family: sans-serif;
			}
		}
	}
}