.btn{
	text-transform: uppercase;
	&:not(.btn-fab){
     min-width: 192px;
     height: 50px;
	}
	&.btn-fab{
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}
}
.btn-light{
	border: 1px solid $secondary;
}
.btn:focus 
{
	box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn-primary
{
	background: linear-gradient(135.71deg, #E70180 0%, #B00061 100%);
	min-height: 50px;
	width: 19px;
	font-weight: 600;
	font-size: 17px;
	border: 0px;
	border-color: #B00061;
}
.btn-primary:focus 
{
	box-shadow: none;
}
.btn-primary.focus, .btn-primary:focus {
    box-shadow: none;
}
.slick-dots li button::before 
{
	font-size: 80px;
	line-height: 0px;
}
.btn-banner
{
	background: linear-gradient(135.71deg, #E70180 0%, #B00061 100%);
	min-height: 60px;
	width: 260px;
	font-weight: 600;
	font-size: 21px;
	color: #fff;
}
.btn-outline-secondary
{
	width:190px;
	height:50px;
	font-size: 17px;
	font-weight: 600;
}
.btn-outline-secondary:hover, .btn-outline-secondary:active, .btn-outline-secondary:focus, 
{
	background-color: #B00061 !important;
	border-color: #B00061;
	color: #fff;
	box-shadow: none;
}
.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus
{
	background-color: #6B1A31; 
	border-color: #6B1A31;
	color:#fff;
}

.btn-option 
{
	border: 1px solid #DCE0E2;
	border-radius: 4px;
	background-color: $white ;
	box-shadow: 0 2px 3px 0 rgba(219,219,219,0.5);
	color:$txt-color;
	font-size: 15px !important;
	width:100%;
	background: transparent;
	font-weight: normal;
	transition: all 1s ease;
	height:45px !important;
	text-transform: none !important;
}
.btn-option:hover, .btn-option:active, .btn-option:focus 
{
	border: 1px solid #DCE0E2;
	background-color: #CA868C;
	box-shadow:0 2px 3px 0 rgba(219,219,219,0.5);
	color:$white;
}
.btn-rect {
	border-radius: 0px;
	border: 1px solid $dark;
	color: $dark;
	width: 125px;
	height: 50px;
	min-width: inherit !important;
}

.btn-rect:hover, .btn-rect:active, .btn-rect:focus {
	background-color: $dark;
	color: $white;
	border-color:$dark;
}
// .btn:hover 
// {
// 	-webkit-transition-duration: 0.5s; /* Safari */
// 	  transition-duration: 0.5s;
// 	  border:1px solid  transparent;
// }
@include media-breakpoint-down(xs){
    .btn{
        &:not(.btn-fab){
         min-width: 150px;
         height: 40px;
        }
        &.btn-fab{
            width: 70px !important;
            height: 70px !important;
            font-size: 10px !important;
        }
	}
	.btn-outline-secondary
    {
        min-width: inherit ;
        font-size: 14px;
        width:150px;
    }
}
