#main-loader
{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #fff;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
	.logo-holder
	{
		
	}
    
    .ver-box, .hor-box{
    	position: absolute;
    	top: 0;
    	left: 0;
    	right: 0;
    	bottom:0;
    	transform: scale(0);
    	transform-origin: center center; 
    	
    }
	.hor-box{
		
		border-left: 3px solid theme-color('secondary');
		border-right: 3px solid theme-color('secondary');
	}

	.ver-box
	{
		border-bottom: 3px solid theme-color('secondary');
		border-top: 3px solid theme-color('secondary');
	}
   

   & + #main-wrapper
   {
   	   main,#main-header,#main-footer{
   	   	opacity: 0;
   	    visibility: hidden;
   	    //transform: scale(.9);
   	    //transform-origin: center top;
   	   }
   	    
   }

}