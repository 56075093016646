.detailed {
    padding-top: 0px;
    padding-bottom: 130px;
	background-color: $white;
	background: none;
    .container {
        max-width: 1140px;
    }

    p {
        text-align: left;
        margin-top: 30px;
    }

    .user-name {
        text-align: left;
    }

    .user-pic {
        margin: 0;
    }

    .cnts {
        padding-bottom: 53px;
        padding-top: 50px;
        border-bottom: 1px solid #ddd;
    }

    .cnts:nth-child(5) {
        border: 0px;
    }

    .pagination {
        margin-top: 14px;
    }
}

@media screen and (max-width: 575px) {
    .detailed {
        padding-top: 15px;
        padding-bottom: 30px;

        .cnts {
            padding-top: 20px;
			padding-bottom: 0px;
			margin: auto;
			text-align: center;
		}
		.user-pic {
			margin: 0 auto;
		}
		p {
			text-align: center;
			margin-top: 30px;
		}
		.cnts:nth-child(1) { 
			padding-top: 0;
		}
		.user-name {
			text-align: center;
		}
		.pagination 
		{
			display: flex;
			justify-content: center;
			float: none;
		}
    }
}
@media screen and (min-width: 576px) and (max-width:991px) 
{
	.detailed {
        padding-top: 15px;
        padding-bottom: 70px;
		background: none;
		background-color: $white;
        .cnts {
            padding-top: 20px;
			padding-bottom: 0px;
			margin: auto;
			text-align: center;
		}
		
		.cnts:nth-child(1) { 
			padding-top: 0;
		}
		p, .user-name 
		{
			margin-left: 20px;
		}
		

		.pagination 
		{
			margin-top: 10px;
			padding: 0;
			
		}
    }
}
