
// xs: 0,
// sm: 480px,
// md: 1024px,
// lg: 1200px
    // @include media-breakpoint-up(sm) {
    // }
    // @include media-breakpoint-down(md) {
    // }

    // @include media-breakpoint-up(sm){
    // 	.who-we-are{
    // 		.sm-screen{ 
    // 			display: none !important; 
    // 		}
    // 		.lg-screen{
    // 			display: block !important; 
    // 		}
    // 	}
    // }

@include media-breakpoint-down(md) {
	.detect-animate{
		transform: none !important;
	}
	body{
		padding-top: 96px;
	}

	.mobile-toggle-button{
		display: block;
	}
	.logo{
		width: 100px;
	}
    .main-menu{
    	margin: 0;
    	position: fixed;
	    display: block;
		background:rgba(255,255,255, 0.9);
	    top: 0;
	    transition: all .5s ease;
	    right: -100%;
	    height: 100vh;
	    width: 300px;
		padding: 25px;
		padding-top: 100px;
		z-index: 5;
		line-height: 25px;
	    & > ul{
	    	display: block;
	    }

	    a{
			color: $pink;
			font-weight: 700;
	    }
	}
	.recruit-btn
	{
		z-index: 4;
	}
    .show-menu{
    	.main-menu{
    		right: 0;
    	}
    }

    .recruit-btn{
    	right: 25px;
    }

    .bg-img{
    	background-position: right center;
    }
    .who-we-are{
    	.content{
	    	padding-left: $grid-gutter-width/2;
	    }
  //   	.bg-img{
		// 	height: 250px;
		// 	margin-bottom: 20px;
		// }
    }

    .what-we-do{
    	figure{
    		display: none;
    	}
    	.content-block{
    		width: 100%;
    		&.left-block{
    			padding: 55px;
    			margin-bottom: 25px;
    			&::before{
    				content: none;
    			}
    		}
    		&.top-block{
				position: static;
				transform: none;
    		}
    	}
    }

    .chat{
    	height: auto;
    	padding: 50px 0;
    }

    #main-wrapper #main-header+main{
    	padding-top: 15px;
    }

    .main-banner{
    	.slides{
    		height: 450px;
    	}
	}

	#main-header.qnta-background{
		background-size: initial;
	}

	#main-wrapper #main-header+main.qntabanner{
		background-size: initial;
		background-position: center -94px;
		padding-top: 70px;
	}

	.about .container{
		padding: 45px 15px 30px 15px;
	}
	
	.about-nannies .overlayed-sec{
		padding-top: 14%;
	}

	.content-block{
		padding: 50px 15px;
	}
	
	
}

@include media-breakpoint-down(sm) {
	.footer-menu-wrp{
		display: block;
	}
	.footer-subscribe-sec{
		margin: auto;
		text-align: center;
		.social-icons{
			justify-content: center;
		}
	}

	.main-banner{
		.container{
			padding: 0;
		}
		.slides{
			position: relative;
			color: #fff;
			display: flex !important;
			padding: 0;
			& .row{
			    width: 80%;
			    margin: auto;
			    text-align: center;
			}
			&::before{
				content: "";
			    position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
			    height: 100%;
			    background: rgba(121,15,49,.9);
			}
		}
		.slick-dots{
			display: none !important;
		}
		.social-icons{
			left: 0;
			right: 0;
			justify-content: center;
		}
	}

	.what-we-do .content-block.left-block{
		padding: 40px 30px;
	}

	.who-we-are{
		.bg-img{
			height: 250px;
			margin-bottom: 20px;
		}
		.btn{
			float: none;
			margin: auto;
			display: block;
		}
	}

	.chat{
		.ch-txt{
			width: calc(100% - 50px);
		}
		figure{
			position: relative;
		}
		.ch-qnta figure{
			right: -7px;
		}
		.ch-usr figure{
			left: -7px;
		}
	}

	.gallery {
		.slide-img-wrap{
			height: 322px;
		}
		.slides{
			padding: 0 10px;
		}
	}

	.events{
		.events-list-wrapper:first-child{
			margin-bottom: 20px;
		}
		.date{
			width: 78px;
		}
		.event-body{
			flex: 1;
		}
		.events-slides{
			figure{
				display: none;
				& + div{
					max-width: 100%;
				}
			}
		}
		.events-list-wrapper .btn{
			max-width: 150px;
			min-width: auto;
		}
	}

	.menu-top{
		.search-wrp{
			position: static;
			.search-fld{
				position: absolute;
				bottom: 8px;
				width: 100%;
				left: 0;
				padding: 0 15px;
			}
			.search{
				position: absolute;
				right: 20px;
				bottom: 19px;
			}
			input{

			}
		}
	}
	#main-header .header-inner{
		padding-bottom: 50px;
	}

	.nanny-form {
		padding: 35px 20px;
		width: 380px;
		.form-close{
			position: absolute;
			top: 0;
			right: 0;
		}
	}

	.about-nannies .overlayed-sec{
		padding-top: 0px;
	}

	.content-block{
		padding: 35px 15px;
	}
	
}

@include media-breakpoint-down(xs){
	.nanny-form{
		width: 290px;
		padding-right: 0;
		.form-group{
			& > div:nth-child(2){
				margin-top: 15px;
			}
		}
	}

	.footer-subscribe-sec{
		width: 250px;
	}

 	.map .address-wrapper{
		padding-left: 0;
	}

	h1 
	{
		font-size: 26px;
		color: #fff;
	}
	.btn-banner
	{
		width: 180px;
		height:40px !important;
	}
	.banner-description 
	{
		color: $white !important;
	}
	.btn-banner
	{
		height: 40px;
		min-height: inherit;
		background: $white !important;
		color: $pink;
		font-size: 14px;
	}
	.btn-banner:hover, .btn-banner:active, .btn-banner:focus 
	{
		
		color: $pink;
	}
	h2 
	{
		font-size: 23px;
	}
	.main-banner
	{
		.social-icons 
		{
			a 
			{
				background-color: $white;
			}
			i 
			{
				color: $pink;
			}
		}
	}
	.btn-primary 
	{
		min-height: inherit;
		height:40px;
		width:150px;
		font-size: 14px;
	}
	.chat .ch-txt
	{
		font-size: 14px;
	}
	.footer-menu
	{
		display: none;
	}
	p 
	{
		font-size: 14px;
	}
	.footer-copyright, .footer-menu ul 
	{
		font-size: 9px !important;
	}
	
}

@media screen and (min-width:1400px)
{
	
	.content
	{
		padding-left: 81px !important;
	}
}
@media screen and (min-width:768px) and (max-width:991px)
{
	.what-we-do
	{
		.container
		{
			padding-right: 79px;
			padding-left: 79px;
		}
	}
	.main-banner, .gallery, .events, .map, 
	{
		.container
		{
			padding-right: 49px;
			padding-left: 49px;
		}
	}
	.chat
	{
		.container
		{
			padding-right: 105px;
			padding-left: 105px;
		}
	}
	.footer-menu-wrp
		{
			padding-right: 43px;
			padding-left: 43px;
		}
	
}
@media screen and (max-width:1199px)
{
	.chat 
	{
		.bg-image
		{
			background-size: cover;  
			
		}
	}
}