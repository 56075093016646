$hdng: #7c6568;
$pink: #af0360;
$white: #fff;
$txt-color: #262626;

.main-menu {
    ul {
        font-family: "Trajan Pro";
    }

    a:focus, a:active {
        font-weight: 700;
        outline: 0;
    }
}

.about-cooparation {
    padding-top: 60px;
    padding-bottom: 60px;
    float: none;
    display: block;
    justify-content: center;
    width: 100%;
    background: #fff;

    .cooparate-section {
        display: flex;

        h6 {
            font-family: "Montserrat",sans-serif;
            font-size: 20px;
            color: $txt-color;
            margin-right: 40px;
            margin-top: 14px;
            font-weight: normal;
        }
    }
}

.social-icons {
    a:hover, a:active, a:focus {
        background-color: $pink;
        color: $white;
    }
}

.form-control:focus {
    box-shadow: none !important;
    border: 1px solid $pink !important;
    transition: all .1s linear;
}

.form-control {
    border: 1px solid  transparent !important;
    font-size: 14px;
}

.custom-file-input:focus ~ .custom-file-label {
    box-shadow: none !important;
    border: 1px solid $pink !important;
    transition: all .55s linear;
}

.form-section .inputbox textarea:focus {
    box-shadow: none !important;
    border: 1px solid $pink !important;
    transition: all .55s linear;
    outline: 0;
}

.medium:focus {
    box-shadow: none !important;
    border: 1px solid $pink !important;
    transition: all .55s linear;
    outline: 0;
}

.event-body {
    .btn-secondary {
        width: 154px;
        height: 36px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        min-width: inherit;
        text-transform: inherit;
    }
}

.address-block {
    .txt-normal {
        line-height: 22px;
        margin-top: 0;
        font-size: 14px;
    }
}

.footer-title {
    font-size: 16px;
}

.footer-subscribe-sec {
    .footer-title {
        font-size: 16px;
        font-weight: normal;
    }
}

.txt-box-section {
    height: 40px;

    .input-group-append {
        height: 40px;

        .btn-primary {
            height: 40px;
            min-height: inherit;
        }

        .txt-box {
            font-size: 14px;
            font-weight: 300;

            ::placeholder {
                color: $txt-color;
                font-weight: 300;
            }
        }
    }
}

.form-cnt {
    .row {
        margin-right: 0;
        margin-left: 0;
    }

    .col-sm-12 {
        padding: 0;
        margin: 0;
    }

    .form-control {
        height: 40px;
    }
}

.main-menu {
    a {
        font-size: 13px;
    }
}

.slide-title {
    color: $white;
}

.footer-copyright {
    font-size: 14px;
}

.footer-menu {
    ul {
        font-size: 14px;
        line-height: 24px;
    }
}

.scrolled #main-header .logo {
    padding-bottom: 10px;
}

.language-switch {
    a {
        color: $hdng !important;
    }
}

.nopad {
    margin: 0;
    padding: 0;
}

.nanny-form .custom-file-label {
    height: 40px;
    color: $hdng;
}

.custom-file-label::after {
    height: 40px;
}

h1, h2, h3 {
    font-weight: 700;
}

.footer-title {
    font-weight: 600;
    font-size: 16px;
    color: $hdng;
}

.banner-description {
    color: $hdng;
    font-size: 17px;
}

.events .date {
    color: #7c6568 !important;
}

a:hover {
    color: $pink;
}

.gallery {
    h4 {
        font-family: 'Montserrat', sans-serif;
    }
}

h1, h2, h3, h4, h5, h6 {
    text-transform: none !important;
}

.map {
    a img {
        width: 100%;
        overflow: hidden;
    }
}

.map .background {
    z-index: auto;
}

.map .address-wrapper {
    pointer-events: none;
}

.map .address-block {
    pointer-events: auto;
}
