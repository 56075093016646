#main-header{
	.header-inner{
		padding: 15px 0;
	}
	
	position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    
    background-color: $white;
    .logo{
		max-width: 180px;
		img 
		{
			margin-bottom: 15px;
		}
    }
}
.p-l-0
{
	padding-left: 0 !important;
}
.p-r-0
{
	padding-right: 0 !important;
}
#main-header.qnta-background{
	background-image: url('../img/bg-header.png');
	background-repeat: no-repeat;
	background-attachment: fixed;
    background-position: center top;
	background-size: 100% auto; 
}

.menu-top{
	display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .search{
    	border: none;
    	background: none;
    	cursor: pointer;
		outline: none;
		padding-left: 0;
    }
    .language-switch{
    	cursor: pointer;
    	font-weight: 700;
    }
    .search-wrp{
		width: auto;
		align-items: center;
		.input-group-append {
			background: transparent;
			z-index: 1;
			height: 33.5px;
		}
		.search-fld{
			position: absolute;
			left: 30px;
			transition: all .5s ease;
			 width: 0; 
			overflow: hidden;
		}
		input{
			border: none;
			margin: 4px;
			width: calc(100% - 8px);
		}
		&.sh-search{
			.search-fld{
				width: 200px;
			}
		}
    }
}

.mobile-toggle-button{
	display: none;
}

.main-menu{
	margin-top: -46px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	ul{
		display: flex;
		margin: 0;
		li:not(:last-child){
			margin-right: 5px;
		}
		a{
			padding: 5px 10px ;
			display: inline-block;
			color:$dark !important;
			cursor: pointer;
		}
	}
	.dropdown-menu {
		width:auto;
		min-width:auto;
		padding: 0 !important;
		border: 1px solid #E6E6E6;
		box-shadow: 0 2px 5px 0 rgba(206,206,206,0.5);
		background-color: #FFFFFF;
		a 
		{
			padding: 15px 26px !important;
			font-family: "Montserrat",sans-serif !important;
			font-family: 14px;
			img 
		{
			margin-right: 15px;
			margin-top: -5px;
		}
		}
		a:active 
		{
			background-color: #f4f4f4;
			font-weight:normal;
		}
		.divider
		{
			width: 100%;
			background-color: #979797;
			height: 1px;
			opacity: 0.3;
		}
	}
}

.mobile-toggle-button{
	cursor: pointer;
	position: absolute;
  // float: right;
	width: 48px;
	height: 30px;
    right: 10px;
    top: 44px;
    z-index: 9;
    /* border: 1px solid #d1d3d4; */
		padding: 5px;
		overflow: hidden;
		// background: #ffffff;
border-radius: 3px;
}


.mobile-toggle-button span{
	position: absolute;
	width: 35px;
	display: block;
	height: 4px;
	background-color: $primary;
	left: 5px;
	transition: all .3s ease;
	border-radius: 20px;
}

.mobile-toggle-button.act-menu span{
	transition: top .2s ease 0s, transform .4s ease .2s;
}

.mobile-toggle-button span:first-child{
	top: 7px;
}

.mobile-toggle-button span:nth-child(2){
	top: 15px;
}

.mobile-toggle-button span:nth-child(3){
	top: 23px;
}

.mobile-toggle-button.act-menu span:first-child{
	top: 14px;
	transform: rotate(45deg);
}

.mobile-toggle-button.act-menu span:nth-child(2){
	transform: translateX(60px);
}

.mobile-toggle-button.act-menu span:nth-child(3){
	top: 14px;
	transform: rotate(-45deg);
}

@media screen and (max-width:1199px)
{
	.main-menu 
	{
		.dropdown-menu
		{
			background-color: transparent;
			box-shadow: none !important;
			border:none;
			a 
			{
				padding: 10px 15px !important;
			}
		}
		ul a {
			
			color: $dark !important;
			img 
			{
				display: none;
			}
		}
}
}