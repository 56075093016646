.footer-menu-wrp{
	display: flex;
	justify-content: space-between;
	padding: 50px 0;
}

.footer-menu{
	display: flex;
	& > ul{
		margin-right: 75px;
	}
}
.ftr 
{
	background-color: #fcfcfc;
}
.footer-subscribe-sec{
	width: 300px;
    text-align: right;
    .footer-title{
    	text-align: left;
    	font-family: "Montserrat",sans-serif;
    }
	input{
		border: none;
		background: $input-bg;
	}
	.btn{
		border-radius: 0;
		height: 40px;
    	width: 40px;
    	min-width: 40px;
	}
	.social-icons{
		justify-content: flex-end;
		    margin-top: 20px;
	}
}


.footer-copyright{
	padding: 40px 0 67px 0;
	border-top: 1px solid $border-light; 
	.social-icons
	{
		margin-bottom: 20px;
		justify-content: center;
	}
}
@media screen and (max-width:767px)
{
	.footer-copyright
	{
		padding: 30px 0px !important;
		.social-icons
		{
			font-size: 14px !important;
		}
	}
	.footer-logo 
	{
		margin: auto;
		text-align: center;
	}
	.footer-menu-wrp
	{
		padding: 30px 0px !important;
	}
}
@media screen and (min-width:768px) and (max-width:991px)
{
	.footer-menu > ul
	{
		margin-right: 30px;
	}
	.footer-logo 
	{
		width:30%;
		margin-right: 10px;
	}
	.footer-menu-wrp
	{
		padding: 30px 0px !important;
	}
	#main-footer .container 
	{
		padding-left: 49px;
		padding-right: 49px;
	}
}