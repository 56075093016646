// changes by Dev team

.last_box_round {
    border-radius: 70px !important;
    margin-left: 14%;
}

.accordion.active .fa-plus:before {
    content: "\f068" !important;
}

#top-menu .current-menu-item {
    font-weight: 700;
}

.main-menu {
    a:hover {
        color: $dark;
    }
}

.padding-nannies, .parent-info {
    figure {
        margin: auto;
        text-align: center;
    }
}

.nannies-apply .content a, .nannies-qnta .content a, .nannies-children .content a {
    text-align: center;
    margin: auto;
}

.nanny-form .custom-file-label {
    position: absolute;
    top: 0;
}

.recruit-form .custom-file-label {
    top: 0;
    left: 0;
    width: 100%;
}

#main-footer {
    .txt-box {
        border-radius: 0px;
    }
}

.no-right {
    margin-right: 0 !important;
}

#main-nav a:active {
    font-weight: 700;
}

.site_map {
    padding-top: 60px;
    padding-bottom: 60px;

    ul {
        list-style: none;
        counter-reset: line;

        li {
            position: relative;
            margin-bottom: 21px;
        }

        li:before {
            position: absolute;
            left: -25px;
            display: inline-block;
            width: 11px;
            height: 11px;
            border: 2px solid #980747;
            margin-right: .5em;
            margin-top: 6px;
            background-color: transparent;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            text-align: center;
            line-height: 1.25em;
            content: "";
        }
    }
}

.pagination li:first-child button {
    margin-right: 15px;
}

.pagination li:last-child button {
    margin-left: 15px;
}

.panel {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}

.about .container, .about-container {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.about-nannies {
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}

.search-fld {
    .form-control {
        border-radius: 0px;
        color: #f6f6f6;
        color: $dark;
    }
}

.testimonials {
    button {
        background-color: $white;
    }
}

.gallery .slider-content {
    h4 {
        font-size: 23px;
        font-weight: 600;
    }

    p {
        font-size: 16px;
    }
}

h3 {
    line-height: 32px;
}

.main-menu ul > :first-child a {
    padding-left: 0px;
}

.main-menu ul > :last-child a {
    padding-right: 0px;
}

.main-banner {
    p {
        color: $dark;
        font-size: 17px;
    }
}

.recruit-btn {
    button:hover, button:active, button:focus {
        color: #fff;
        background-color: #de017b !important;
        border-color: #de017b;
    }

    button {
        font-family: 'Trajan Pro';
    }
}

.what-we-do {
    .bg-primary-dark {
        background-color: #6B1A31 !important;
    }

    .background .mask {
        opacity: 0.9;
    }
}

.padding-nannies {
    .content {
        padding-top: 10px !important;
    }
}

.content {
    a {
        color: $white;
    }

    a:hover {
        font-weight: 600;
        color: $white;
    }
}

.gallery {
    .title-with-btn {
        padding-top: 90px;
        padding-bottom: 60px;
    }
}

.events {
    .title-with-btn {
        padding-top: 88px;
        padding-bottom: 70px;
    }
}

.footer-menu-wrp {
    .txt-box {
        background-color: #f6f6f6;
    }
}

.footer-copyright {
    color: $dark;
}

.who-we-are {
    .bg-image {
        background-position: right;
    }
}

.slick-slide .slider-content {
    p.txt-white {
        height: 124px;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        margin: 0 auto;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.scrolled #main-header .header-inner {
    box-shadow: none !important;
}

#main-footer {
    background-color: #fcfcfc;
}

.custom-file-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.nopad {
    margin: 0 !important;
    padding: 0 !important;
}

.nanny-form {
    z-index: 1000;

    .row {
        margin-right: 0;
        margin-left: 0;
    }

    .col-lg-12, .col-md-12, .col-sm-12 {
        padding-left: 0;
    }

    button {
        margin-bottom: 20px;
    }
}

.dsp-mob {
    display: none;
}

.nanny-form .custom-file .custom-file-label {
    font-size: 10px;
    padding-top: 13px;
}

.nanny-form .custom-file .custom-file-label::after {
    font-size: 14px;
    padding-top: 9px;
}

.what-we-do {
    .left-block {
        padding-left: 0 !important;
    }
}

.footer-title {
    margin-bottom: 0px;
}

.footer-menu-wrp {
    padding-bottom: 30px !important;
}

.padding-nannies {
    padding-top: 13px;
}

.who-we-are .p-l-55 {
    padding-left: 55px !important;
}

@media screen and (max-width: 424px) {
    .about {
        margin-top: 20px;
    }

    .about .container {
        padding-bottom: 0px;
    }

    .about-nannies {
        height: 100%;
    }

    .about-nannies .content-block h3 {
        padding-top: 0px;
    }

    .testimonials {
        padding: 35px 0;
    }
}

.about-nannies {
    button {
        margin: auto;
    }
}

@media screen and (max-width: 575px) {
    .chat .ch-txt {
        padding: 20px 23px;
    }

    .who-we-are .p-l-55 {
        padding-left: inherit !important;
        padding-right: 0 !important;
    }

    .main-banner {
        p {
            color: $white;
        }

        button {
            background: $white !important;
            color: #980747 !important;
        }
    }

    .about {
        margin-top: 0px !important;

        .container {
            padding-top: 30px !important;
            padding-bottom: 30px !important;
        }
    }

    .who-we-are {
        .content {
            padding-top: 30px;
        }

        p {
            padding-left: 32px;
            padding-right: 32px;
        }
    }

    .gallery {
        .title-with-btn {
            padding-top: 33px;
            padding-bottom: 30px;
        }

        h2 {
            font-size: 24px;
        }

        .v-all-btn-mob {
            min-width: inherit;
            font-size: 14px;
            width: 150px;
        }

        [class*="icono-arrow2"] {
            margin-top: 5px;
            border-width: 4px;
        }
    }

    .events {
        .title-with-btn {
            padding-top: 46px;
            padding-bottom: 42px;
        }

        .v-all-btn-mob {
            min-width: inherit;
            font-size: 14px;
            width: 150px;
        }

        [class*="icono-arrow2"] {
            margin-top: 5px;
            border-width: 4px;
        }
    }

    .what-we-do {
        padding-top: 44px;
        padding-bottom: 23px;

        .content {
            padding-left: 32px;
            padding-right: 32px;
            margin-bottom: 0 !important;
        }

        .content-block {
            margin-bottom: 13px !important;
        }

        .txt-white a {
            display: block;
            margin-top: 20px;
        }
    }

    .map {
        height: 600px;
        z-index: 3;

        .address-wrapper {
            top: 70%;
        }
    }

    .what-we-do .left-block {
        padding-left: 32px !important;
    }

    .main-menu ul > :first-child a {
        padding-left: 10px !important;
    }

    .recruit-btn {
        top: 85%;
    }

    .nanny-form {
        width: 90%;

        .row {
            margin-right: 0;
            margin-left: 0;
        }

        .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto, .row {
            padding-left: 0 !important;
            padding-right: 0 !important;
            margin-right: 0 !important;
            margin-left: 0 !important;

            .form-control {
                width: 99%;
                font-size: 14px;
            }

            .custom-file-label {
                margin: 0;
                max-width: 100%;
                width: 100%;
                left: 0;
                font-size: 14px;
            }
        }
    }

    .about-cooparation {
        .cooparate-section {
            display: block !important;
            margin: auto;
            text-align: center;

            h6 {
                font-size: 14px !important;
                color: $txt-color;
                margin-right: 0px !important;
                margin-top: 15px !important;
            }

            button {
                float: none;
                margin: auto;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .what-we-do {
        padding-top: 72px;
        padding-bottom: 86px;
    }

    .who-we-are {
        .content {
            padding-left: 32px;
            padding-right: 49px;
        }
    }

    .about-cooparation, .about-nannies {
        .container {
            padding-left: 49px !important;
            padding-right: 49px !important;
        }
    }

    .nanny-form {
        width: 90%;
    }

    .what-we-do .content-block.left-block {
        margin-bottom: 16px;
    }

    .chat {
        .bg-image {
            background-position: right;
        }
    }

    .gallery {
        .title-with-btn {
            button {
                display: block !important;
            }
        }

        .v-all-btn-mob {
            display: none;
        }

        .slides {
            padding: 0 15px;
        }
    }

    .events .title-with-btn {
        padding-top: 70px;
        padding-bottom: 55px;
    }

    .events {
        .title-with-btn {
            button {
                display: block !important;
            }
        }

        .v-all-btn-mob {
            display: none;
        }

        .event-body .read-more {
            color: $txt-color;
        }

        .slick-slide {
            width: 240px !important;
        }
    }

    .main-menu ul > :first-child a {
        padding-left: 10px !important;
    }

    .header-inner {
        .container {
            padding-right: 49px;
            padding-left: 49px;
        }
    }

    .mobile-toggle-button {
        right: 41px;
    }

    .about-container {
        padding-top: 0px !important;
    }

    .about, .qntabanner {
        .container {
            padding-left: 49px !important;
            padding-right: 49px !important;
        }
    }

    .what-we-do {
        .col-lg-6:nth-child(2n+1) {
            padding-right: 15px !important;
        }

        .content-block {
            padding: 60px !important;
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .main-menu ul > :first-child a {
        padding-left: 10px !important;
    }

    .mobile-toggle-button {
        right: 41px;
    }

    .container {
        padding-left: 49px !important;
        padding-right: 49px !important;
    }

    .who-we-are {
        .container {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .about-cooparation {
        .container {
            padding-left: 49px !important;
            padding-right: 49px !important;
        }
    }

    .about-container {
        padding-top: 60px !important;
        padding-left: 0;
        padding-right: 0;
    }

    .about {
        padding-bottom: 40px;

        .container {
            padding-left: 49px !important;
            padding-right: 49px !important;
        }
    }

    .padding-nannies, .nannies-cooperation {
        .content {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    .xtend, .xtend-rgt {
        margin: 0;
    }

    .parent-info {
        padding: 0;

        .content {
            padding: 40px;
        }
    }

    .parents-accordian {
        padding-top: 50px;
        padding-bottom: 120px;
    }
}

@media screen and (max-width: 767px) {
    .dsp-mob {
        display: block;
    }

    .map .address-block {
        display: none;
    }

    .about-cooparation {
        padding-top: 30px !important;
        padding-bottom: 70px !important;
        float: none !important;
        display: block !important;
    }

    .xtend, .xtend-rgt {
        margin: 0;
    }

    .address-block-mob {
        padding: 20px;
        border-bottom: 1px solid #e9ecef;
        text-align: center;
        font-size: 14px;
        line-height: 30px;

        figure {
            margin-bottom: 20px;
        }
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .main-menu ul > :first-child a {
        padding-left: 10px !important;
    }

    .main-menu ul > :last-child a {
        padding-right: 10px !important;
    }

    .what-we-do .content-block.left-block {
        padding: 35px 15px !important;
    }

    .main-banner {
        h1, p {
            color: $white;
        }

        button {
            background: #fff !important;
            color: #980747 !important;
        }

        .social-icons a {
            background-color: #fff;
        }

        .social-icons i {
            color: #980747;
        }
    }

    .nannies-training {
        padding-bottom: 0 !important;

        .container {
            padding-left: 0px;
            padding-right: 0px;
        }

        ul {
            padding-left: 25px;
        }
    }

    .nannies-cooperation {
        .content {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .tab-btn a .svg-label {
        display: none;
    }

    .svg-align {
        margin: 0 !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .chat {
        height: 700px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
    .events .clearfix {
        max-width: 100%;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .main-banner {
        .row {
            display: block;
        }
    }

    .menu-top {
        .search-fld {
            top: -7px;
        }
    }

    .chiller_cb input:checked ~ span {
        top: 5px;
    }

    .thanku-section .download-section {
        display: flex;
        justify-content: center;

        p {
            margin-right: 14px;
            margin-top: 14px;
        }
    }

    .about-nannies {
        .content {
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 575px) {
    .thanku-section .download-section {
        display: block;
        justify-content: inherit;

        p {
            margin-right: auto;
            margin-top: auto;
        }
    }

    .main-about .p-l-55 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

@media screen and (min-width: 1366px) and (max-width:1399px) {
    .main-about .container {
        margin-left: inherit;
        max-width: 1239px;
        padding-right: 0;
    }
}
@media screen and (min-width: 1400px) and (max-width:1450px) {
    .main-about .container {
        margin-left: inherit;
        max-width: 1275px;
        padding-right: 0;
    }
}
@media screen and (min-width: 1451px)  {
    .main-about .container {
        margin-left: inherit;
        max-width: 1347px;
        padding-right: 0;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1365px) {
    .main-about {
        .container {
            max-width: 100%;
            overflow: hidden;
        }

        .p-l-55 {
            padding: 55px !important;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .who-we-are .container {
        padding-left: 49px !important;
        padding-right: 49px !important;
    }

    .main-about .container {
        padding-left: 0 !important;
    }

    .nannies_heading .container {
        padding-left: 49px !important;
        padding-right: 49px !important;
    }

    .parent-info .content {
        padding-top: 40px;
        padding-right: 0 !important;
    }
    .tab-btn a .svg-label {
        display: none;
    }

    .svg-align {
        margin: 0 !important;
    }

}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .about .about-container {
        padding-top: 0 !important;
    }

    .about {
        padding-bottom: 0;
    }

    /* .who-we-are .container 
    {
        padding-left: 49px !important;
        padding-right: 49px !important;
    } */
    .nannies_heading .container {
        padding-left: 49px !important;
        padding-right: 49px !important;
    }

    .padding-nannies .container, .parent-info .container {
        padding-left: 49px !important;
        padding-right: 49px !important;
    }

    .what-we-do .content-block.left-block {
        padding: 50px 15px !important;
        margin-bottom: 25px;
    }

    .padding-nannies .content {
        padding-top: 10px;
    }
    .tab-btn a .svg-label {
        display: none;
    }

    .svg-align {
        margin: 0 !important;
    }
    .tab-btn a 
    {
        padding: 12px 60px 10px;
    }
}
