.faq 
{
	background-color: $white;
	padding: 0;
	margin-top:0;
	margin-bottom: 65px;

	img 
	{
		width:100%;
		margin-top: 20px;
		margin-bottom: 50px;
	}
}
.parents-accordian .accordion:hover, .parents-accordian .active {
    background-color: $white;
}
@media screen and (max-width:575px)
{
	.faq 
	{
		padding-top: 30px;
		padding-bottom: 15px;
		margin-bottom: 0px;
		img 
		{
			margin-top: 0;
			margin-bottom: 15px;
		}
	}
}
@media screen and (min-width:576px) and (max-width:991px)
{
	.faq 
	{
		margin-bottom: 0px;
		img 
		{
			margin-top: 0;
			margin-bottom: 15px;
		}
	}
}