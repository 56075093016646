.slider-wrapper{
	position: relative;
}
.slider{
	margin-bottom: 0;
}

.main-banner{	
	.container{
		position: relative;
	}
	.slides{
		// padding: 20px 0;
		padding: 0;
	    min-height: 450px;
	    height: 70vh;
	    background-size: cover;
	    background-position: center center;
	    position: relative;
	    .container{
	    	height: 100%;
	    	display: flex;
	    	align-items: center;
	    }
	    
	}
	.slick-dots{
		position: absolute;
		text-align: right;
    	padding-right: 0px;
    	bottom: 30px;
    	left: 0;
    	right: 0;
    	@extend .container;
	}
	.social-icons{
		position: absolute;
		bottom: 30px;
	}
}

.background
{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	overflow: hidden;
	.mask{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0.8;
	}
}
.bg-image{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;  
	&.parallax-bg{
		top: -200px;
		height: auto;
		bottom: 0;
	}
}
.chat 
{
	.bg-image{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-position: left top;
		background-size: contain;  
		background-repeat: no-repeat;
		&.parallax-bg{
			top: -110px;
			height: auto;
			bottom: 0;
		}
	}
}

.about-bg-image{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;  
	&.parallax-bg{
		top: -200px;
		height: auto;
		bottom: 0;
	}
}

.about-bg-image.rgt{
	right: 0;
	left: auto;
}

.recruit-btn{
	position: fixed;
	right: 69px;
	top: 50%;
	z-index: 999;
	.btn{
		width:90px;
		height: 90px;
		
	    white-space: normal;
	    font-size: 12px;
	    line-height: 18px;
    	//box-shadow: 0 0 0 6px fade-out(theme-color('secondary'),0.2), 0 0 0 12px fade-out(theme-color('secondary'),0.8);
    	animation: ripple-btn linear 2s infinite;
		&:hover, &:active, &:focus{
    		animation: ripple-btn-hover linear 2s infinite;
		}
	}
}

.preload_background
{
  background-image: url(../img/preload_pattern.png)!important;
}

.preload_background {
    background-repeat: repeat!important;
    background-color: transparent!important;
    background-attachment: scroll!important;
    background-size: inherit!important;
    animation: animatedBackground 40s linear infinite;
}
