.padding-nannies {
    padding-top: 35px;
    min-height: inherit;
}
.nannies_heading p
{
    margin-top: 33px;
}
.what-we-do .left-block a, .what-we-do .top-block a
{
    color:#262626 !important;
    font-weight: 700;
}
.what-we-do .content a 
{
    font-weight: 700;
}

@media screen and (max-width:575px)
{
    #main-wrapper #main-header + main.qntabanner {
        background-position: center -94px;
        padding-top: 85px !important;
    }
    .thanku-section
    {
        padding-top: 90px;
    }
    .who-we-are .btn-primary
    {
        color:#fff !important;
    }
    .testimonials {
        padding-bottom: 60px !important;
    }
    .rtl .pagination
    {
        float: none !important;
    }
    input.nextbin {
        background: #e70180 url(../wp-content/themes/twentyseventeen/img/Arrow.svg) no-repeat 100px center !important;
            background-size: auto auto;
        background-size: 18px 18px;
        cursor: pointer;
    }
    input.nextbin:hover {
        background: #e70180 url(../wp-content/themes/twentyseventeen/img/Arrow.svg) no-repeat 130px center !important;
            background-size: auto auto;
        background-size: 18px 18px;
        cursor: pointer;
    }
	.padding-nannies .content p 
	{
		padding: 0 !important;
	}
	.map{position:relative;height:300px}
}
figure img 
{
    margin: 0;
}
.about .container 
{
    padding-bottom: 0 !important;
}
@media screen and (min-width:576px) and (max-width:767px)
{
    #main-wrapper #main-header + main.qntabanner {
        background-position: center -94px;
        padding-top: 85px !important;
    }
    input.nextbin {
        background: #e70180 url(../wp-content/themes/twentyseventeen/img/Arrow.svg) no-repeat 100px center !important;
            background-size: auto auto;
        background-size: 18px 18px;
        cursor: pointer;
    }
    input.nextbin:hover {
        background: #e70180 url(../wp-content/themes/twentyseventeen/img/Arrow.svg) no-repeat 100px center !important;
            background-size: auto auto;
        background-size: 18px 18px;
        cursor: pointer;
    }
}
@media screen and (min-width:768px) and (max-width:991px)
{
    .who-we-are .container 
    {
        padding-left: 49px !important;
        padding-right: 49px !important;
    }
    .main-about .container 
    {
        padding-left: 0 !important;
    }
    .nannies_heading .container 
    {
        padding-left: 49px !important;
        padding-right: 49px !important;
    }
    .parent-info .content 
    {
        padding-top: 40px;
        padding-right: 0 !important;
    }
}
@media screen and (min-width:992px) and (max-width:1199px) 
{
   .about  .about-container 
    {
        padding-top: 0 !important;
    }
    .about 
    {
        padding-bottom: 0;
    }

    .nannies_heading .container 
    {
        padding-left: 49px !important;
        padding-right: 49px !important;
    }
    .padding-nannies .container, .parent-info .container
    {
        padding-left: 49px !important;
        padding-right: 49px !important;
    }
    .what-we-do .content-block.left-block {
        padding: 50px 15px !important;
        margin-bottom: 25px;
    }
    .padding-nannies .content {
        padding-top: 10px;
    }
    .main-about .container 
    {
        overflow: hidden;
        width:960px !important;
        margin: 0;
    }
}
/* added classes */
.sec-wrapper.row{
	margin-right:0px !important;
}
.who-we-are.main-about.container{
	margin:0px !important;
}

/* about qnta page */
@media screen and (max-width:575px)
{
   .hidden_desktop {
        display:none;
    }
	.hidden_tab {
        display:none;
    }
}

@media screen and (min-width: 576px) and (max-width: 1199px) {
  .hidden_desktop {
        display:none;
    }
	.hidden_mobile {
        display:none;
    }

}

@media screen and (min-width:1200px)
{
  .hidden_tab {
        display:none;
    }
	.hidden_mobile {
        display:none;
    }
}

